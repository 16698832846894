<template>
  <div>
    <portal to="header-toolbar">
      <span class="header-download-csv">
        <a-icon type="download" style="margin-right: 8px;" />
        Unduh CSV
      </span>
      <button
        class="header-button-add"
        @click="openForm"
        v-if="getUserRole !== 'externalUsers-pharmacies-staff'"
      >
        <a-icon type="plus" class="icon" />
        Tambah Apoteker
      </button>
    </portal>
    <dashboard-table
      searchPlaceholder="Cari Apoteker"
      :columnTable="columnsPharmacist"
      :dataTable="getApotekerComputed"
      :pagination="pagination"
      :loadingTable="loading"
      :onSearchTable="onSearch"
      :perPageValue="perPageValue"
      @handleTableChange="handleTableChange"
      @showFilter="openFilterModal"
      @handlePerPageChange="handlePerPageChange"
    >
      <template slot="statusData" slot-scope="{ record }">
        <a-tag class="prixa-ant-tag" :color="record | statusColor">{{ record }}</a-tag>
      </template>
      <template slot="actionDropdown" slot-scope="{ record }">
        <a-menu-item v-if="record.id !== getUserId">
          <a href="javascript:;" @click="handleUpdate(record.id, record.client_id)">Ubah Data</a>
        </a-menu-item>
        <a-menu-item v-if="record.id !== getUserId && record.status === 'pending'">
          <a href="javascript:;" @click="sendVerification(record)">Verifikasi Email</a>
        </a-menu-item>
        <template v-if="record.id !== getUserId">
          <a-menu-divider />
          <a-menu-item>
            <a href="javascript:;"
              @click="handleDelete(record)"
              class="color-danger"
            >
              Hapus Apoteker
            </a>
          </a-menu-item>
        </template>
      </template>
    </dashboard-table>
    <pharmacist-modal
      :modal-title="modalTitle"
      ref="form"
      @fetchData="fetchData"
      @filter="filterData"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Portal } from "portal-vue";
import { mapGetters, mapActions } from "vuex";
import DashboardTable from "@/components/table/DashboardTable.vue";
import PharmacistModal from "@/components/pharmacist/PharmacistModal.vue";

const columnsPharmacist = [
  {
    index: 0,
    title: "No",
    dataIndex: "index",
    key: "index",
    width: 50,
  },
  {
    index: 1,
    title: "Nama",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    index: 2,
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    index: 3,
    title: "Peran",
    dataIndex: "roleText",
    key: "roles",
    sorter: false,
  },
  {
    index: 4,
    title: "Status",
    dataIndex: "statusText",
    key: "status",
    scopedSlots: { customRender: "user_status" },
  },
];

export default Vue.extend({
  data() {
    return {
      isFirst: true,
      columnsPharmacist,
      loading: true,
      pagination: {
        page: 1,
        perpage: 10,
        current: 1,
        total: 0,
        pageSize: 0,
      },
      search: "",
      filter: "",
      sorter: "",
      modalTitle: "",
      perPageValue: 10,
    };
  },
  components: {
    Portal,
    DashboardTable,
    PharmacistModal,
  },
  filters: {
    statusColor(record) {
      let color = "blue";
      if (record === "Terverifikasi") color = "green";
      else if (record === "Tidak Terverifikasi") color = "red";
      return color;
    },
  },
  computed: {
    ...mapGetters("PharmacistStore", ["getPharmacists"]),
    ...mapGetters("UserStore", ["getUserRole", "hasPermission", "getUserId", "getClientId"]),
    getApotekerComputed() {
      const pharmacistData = this.getPharmacists.map((pharmacist, index) => {
        const tmp = pharmacist;
        const role = tmp.roles.length > 0 ? tmp.roles[0].name : "";
        tmp.index = this.pagination.pageSize * (this.pagination.current - 1) + index + 1;
        tmp.companyText = tmp.client !== null ? tmp.client?.client_name : "";
        tmp.roleText = role === "externalUsers-pharmacies-staff" ? "Apoteker" : "";
        if (tmp.status === "active") tmp.statusText = "Terverifikasi";
        else if (tmp.status === "inactive") tmp.statusText = "Tidak Terverifikasi";
        else tmp.statusText = "Verifikasi Tertunda";
        return tmp;
      });
      return this.isFirst ? [] : pharmacistData;
    },
  },
  methods: {
    ...mapActions("UserStore", [
      "getUserData",
    ]),
    ...mapActions("PharmacistStore", [
      "fetchExternalUser",
      "fetchExternalUserByClientId",
    ]),
    openForm() {
      this.modalTitle = "Tambah Apoteker";
      (this.$refs.form).showModal();
    },
    openFilterModal() {
      this.modalTitle = "Filter";
      (this.$refs.form).showFilter();
    },
    onSearch(value) {
      this.search = `&name=${value}`;
      this.pagination.page = 1;
      this.fetchData();
    },
    filterData(filter) {
      this.filter = filter;
      this.pagination.page = 1;
      this.fetchData();
    },
    handleUpdate(recordId, recordClientId) {
      this.modalTitle = "Ubah Data";
      (this.$refs.form).showModal(recordId, recordClientId);
      // alert(`ubah data ${JSON.stringify(record)}`); // eslint-disable-line
    },
    sendVerification(record) {
      this.modalTitle = "Verifikasi Email";
      (this.$refs.form).showVerification(record);
    },
    handleDelete(record) {
      this.modalTitle = "Hapus Apoteker";
      (this.$refs.form).showDelete(record);
    },
    handlePerPageChange(value) {
      this.perPageValue = Number(value);
      const pager = { ...this.pagination };
      pager.perpage = Number(value);
      this.pagination = pager;
      if (pager.perpage > pager.total) {
        this.pagination.page = 1;
      }
      this.fetchData();
    },
    handleTableChange({ pagination, sorter }) {
      if (sorter.order) {
        const sortOrder = (
          sorter.order === "ascend" ? encodeURIComponent("+") : "-");
        this.sorter = `&order_by=${sortOrder}${sorter.columnKey}`;
      } else this.sorter = "";

      const pager = { ...this.pagination };
      pager.page = pagination.current;
      pager.perpage = pagination.pageSize;
      this.pagination = pager;
      this.pagination.perpage = pagination.pageSize;
      this.pagination.page = pagination.current;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      if (this.getUserRole.includes("externalUsers-pharmacies") && this.getClientId) {
        this.fetchExternalUserByClientId({
          clientId: this.getClientId,
          page: this.pagination.page,
          perpage: this.pagination.perpage,
          search: this.search,
          filter: this.filter,
          sorter: this.sorter,
        }).then((data) => {
          const tmpPagination = { ...this.pagination };
          tmpPagination.total = data.total;
          tmpPagination.pageSize = Number(data.per_page);
          tmpPagination.current = Number(data.current_page);
          this.pagination = tmpPagination;
          this.isFirst = false;
          this.loading = false;
        });
      } else {
        this.fetchExternalUser({
          page: this.pagination.page,
          perpage: this.pagination.perpage,
          search: this.search,
          filter: this.filter,
          sorter: this.sorter,
        }).then((data) => {
          const tmpPagination = { ...this.pagination };
          tmpPagination.total = data.total;
          tmpPagination.pageSize = Number(data.per_page);
          tmpPagination.current = Number(data.current_page);
          this.pagination = tmpPagination;
          this.isFirst = false;
          this.loading = false;
        });
      }
    },
  },
  created() {
    this.$emit("setRightSidebarVisible", false);
  },
  mounted() {
    this.getUserData();

    if (this.getUserRole === "externalUsers-pharmacies-admin") {
      this.columnsPharmacist = [
        ...this.columnsPharmacist,
        {
          index: 5,
          title: "Aksi",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 100,
          align: "center",
        },
      ];
    }

    this.fetchData();
  },
});
</script>

<style lang="scss" scoped>
.header-download-csv {
  margin-right: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #55b9e4;
  cursor: pointer;
}

.header-button-add {
  padding: 14px 24px;
  border-radius: 26px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #0146ab;
  outline: none;

  .icon {
    font-size: 16px;
  }

  &:focus {
    background: #1f64b8;
  }

  &:active {
    background: #0146ab;
  }
}

.prixa-ant-tag {
  padding: 2px 16px;
  border-radius: 16px;
}
</style>
