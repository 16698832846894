var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('a-form',{attrs:{"layout":_vm.formLayout,"form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[(_vm.isSubmitError)?_c('div',{staticClass:"margin-baseB"},[_c('a-alert',{attrs:{"type":"error","message":_vm.submitErrorMessage,"banner":""}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"Nama","validate-status":_vm.checkError('name') ? 'error' : '',"help":_vm.checkError('name') || ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: 'Data tidak valid' }] } ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: 'Data tidak valid' }] },\n        ]"}],attrs:{"size":"large","placeholder":"Nama Lengkap"}})],1),_c('a-form-item',{attrs:{"validate-status":_vm.checkError('roles') ? 'error' : '',"help":_vm.checkError('roles') || ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v(" Peran ")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'roles',
          { rules: [{ required: true }] } ]),expression:"[\n          'roles',\n          { rules: [{ required: true }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"size":"large","disabled":true,"show-search":"","placeholder":"Pilih Peran","option-filter-prop":"children","filter-option":_vm.filterOption},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},[_c('a-select-option',{attrs:{"value":"externalUsers-pharmacies-staff"}},[_vm._v(" Apoteker ")])],1)],2),_c('a-form-item',{attrs:{"label":"Email","validate-status":_vm.checkError('email') ? 'error' : '',"help":_vm.checkError('email') || ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          { rules: [{ required: true, message: 'Data tidak valid',
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ }] } ]),expression:"[\n          'email',\n          { rules: [{ required: true, message: 'Data tidak valid',\n          pattern: /^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/ }] },\n        ]"}],attrs:{"size":"large","placeholder":"nama@mail.com"}})],1),(!_vm.clientIdUser)?_c('a-form-item',{attrs:{"label":"Perusahaan","validate-status":_vm.checkError('company') ? 'error' : '',"help":_vm.checkError('company') || ''}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'company',
          { rules: [{ required: _vm.clientIdUser ? false : true }] } ]),expression:"[\n          'company',\n          { rules: [{ required: clientIdUser ? false : true }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"size":"large","show-search":"","placeholder":"Pilih Perusahaan","option-filter-prop":"children","filter-option":_vm.filterOption,"loading":_vm.getCompanies.length < 1,"disabled":_vm.getCompanies.length < 1 || _vm.id !== ''},on:{"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},_vm._l((_vm.getCompanies),function(company){return _c('a-select-option',{key:company.id,attrs:{"value":company.id}},[_vm._v(" "+_vm._s(company.client_name)+" ")])}),1)],1):_vm._e(),_c('a-form-item',[_c('div',{staticClass:"p-modal-button-container"},[_c('a-button',{staticClass:"p-modal-cancel",attrs:{"size":"large"},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")]),_c('a-button',{staticClass:"p-modal-submit",attrs:{"type":"primary","size":"large","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()),"loading":_vm.loading}},[_vm._v(" "+_vm._s(this.id && this.clientId ? "Ubah" : "Tambah")+" ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }