<template>
  <a-form :layout="formLayout" @submit="handleSubmit">
    <div class="margin-baseB" v-if="isSubmitError">
      <a-alert type="error" message="Gagal mengirim verifikasi, mohon ulangi" banner />
    </div>
    <div>
      <span>Aktifkan ulang verifikasi untuk</span> <strong>{{ email }}</strong>?
    </div>
    <a-form-item>
      <div class="p-modal-button-container">
          <a-button
            class="p-modal-cancel"
            size="large"
            @click="handleCancel"
          >
            Batal
          </a-button>
        <a-button
          type="primary"
          size="large"
          html-type="submit"
          :loading="loading"
          class="p-modal-submit"
        >
          Aktifkan
        </a-button>
      </div>
    </a-form-item>
  </a-form>
</template>
<script>

import Vue from "vue";
import { message } from "ant-design-vue";
import { mapActions } from "vuex";

export default Vue.extend({
  data() {
    return {
      formLayout: "vertical",
      loading: false,
      isSubmitError: false,
    };
  },
  props: {
    name: {
      type: String,
    },
    clientId: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  methods: {
    ...mapActions("UserExternalStore", ["resendEmail"]),
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.isSubmitError = false;
      this.loading = true;
      const resendEmailPayload = {
        id: this.clientId,
        data: {
          email: this.email,
          source: "pharmacy_dashboard",
        },
      };
      this.resendEmail(resendEmailPayload)
        .then(() => {
          this.loading = false;
          message.success(`Link verifikasi telah dikirim ke ${this.email}`);
          this.$emit("handleOk");
        })
        .catch(() => {
          this.loading = false;
          this.isSubmitError = true;
        });
    },
  },
});
</script>
